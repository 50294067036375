define(function() {

  
  /*
        Usage example:
        <div class="js-qty-controls">
            <label for="qty-picker" class="quantity">Quantity</label>
            <button data-qc-action="minus">-</button>
            <input type="text" name="quantity" class="js-qc-qty" id="qty-picker" size="2" maxlength="2" value="1" placeholder="1" />
            <button data-qc-action="plus">+</button>
        </div>

        attributes:
        class="js-qty-controls" : all control elements must have this class
        data-qc-action="plus" : denote the action: Options: 'plus' or 'minus'
        data-qc-target="#qty-picker" : denotes Id or Class of target object
    */

  var quantityControls = (function(window) {

    var $qcObjs;

    var activateQuantityControls = function() {

      var qcClickFunc = function(e) {
        var elem = e.target,
          elemAction = elem.getAttribute('data-qc-action'),
          targetElem = elem.parentNode.querySelector('.js-qc-qty');

        if (elem && targetElem && elemAction) {

          var isMinus = elemAction === 'minus',
            qtVal = parseInt(targetElem.value, 10);

          if (qtVal > 0) {

            if (isMinus) {

              if (qtVal > 1) {
                targetElem.value = qtVal - 1;
              }

            } else {
              targetElem.value = qtVal + 1;
            }
          }
        } else {
          console.log('Error: elem : ' + elem + '\ntargetElem : ' + targetElem);
        }

      };
      // loop throug all buttons on this page and attach click functiom to all element with class="js-qty-controls"
      for (var i = 0; i < $qcObjs.length; i++) {
        $qcObjs[i].addEventListener('click', qcClickFunc);
      }
    };

    $qcObjs = document.querySelectorAll('.js-qty-controls');
    if ($qcObjs.length) {
      activateQuantityControls();
    }

  })(window);

});

